import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class LdapUserPublic {
  @JsonProperty('username')
  private _username: string;
  @JsonProperty('firstName')
  private _firstName: string;
  @JsonProperty('lastName')
  private _lastName: string;
  @JsonProperty('email')
  private _email: string;
  @JsonProperty('matriculationNumber')
  private _matriculationNumber: string;

  constructor(username: string, firstName: string, lastName: string, email: string, matriculationNumber: string) {
    this._username = username;
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._matriculationNumber = matriculationNumber;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get matriculationNumber(): string {
    return this._matriculationNumber;
  }

  set matriculationNumber(value: string) {
    this._matriculationNumber = value;
  }
}
