






































































































































































import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { Component } from 'vue-property-decorator';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import UserService from '@/services/UserService';
import LdapUserPublic from '@/models/users/LdapUserPublic';
import { UserRoleEnum } from '@/enums/UserRoleEnum';

@Component
export default class EditUser extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;
  protected breadcrumbs: { text: string; to?: string; active?: boolean }[] | null = null;
  protected ldapUser: LdapUserPublic = new LdapUserPublic('', '', '', '', '');
  protected newPassword = '';
  protected newPasswordAgain = '';
  protected userRoles: string[] = [];

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.setLoading(true);
      const username = this.$route.params.id;
      this.breadcrumbs = [
        { text: 'Home', to: '/' },
        { text: 'Administration', to: '/system' },
        { text: 'Edit User', active: true },
      ];
      this.loadUser(username);
    }
  }

  protected loadUser(username: string): void {
    UserService.getUserInfoById(username)
      .then((result) => {
        this.ldapUser.username = result.uid;
        this.ldapUser.firstName = result.firstName;
        this.ldapUser.lastName = result.lastName;
        this.ldapUser.email = result.email;
        this.ldapUser.matriculationNumber = result.matriculationNumber;
        result.roles.forEach((r) => {
          this.userRoles.push(UserRoleEnum[r]);
        });
        if (result.matriculationNumber === '0') {
          this.ldapUser.matriculationNumber = '';
        }
        this.setLoading(false);
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load user. ' + backendError.response.data.message, 'danger');
        }
        this.$router.replace({ path: '/system' });
      });
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected saveUser(): void {
    this.setLoading(true);
    UserService.updateUser(this.ldapUser)
      .then(() => {
        this.setLoading(false);
        this.$router.replace({ path: '/system' });
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to update', 'Could not update user. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected updatedGroups(): void {
    this.setLoading(true);
    let newRolesEnum: string[] = [];
    this.userRoles.forEach((r) => {
      for (const key in UserRoleEnum) {
        if (UserRoleEnum[key] === r) {
          newRolesEnum.push(key);
        }
      }
    });
    UserService.updateUserGroups(this.ldapUser.username, newRolesEnum)
      .then(() => {
        this.setLoading(false);
        this.showToast('Groups updated', 'Groups assigned to user have been updated.', 'success');
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to update',
            'Could not update groups. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected updatePassword(): void {
    this.setLoading(true);
    if (this.newPassword !== this.newPasswordAgain) {
      this.setLoading(false);
      this.showToast('Action denied', 'Passwords do not match.', 'danger');
      return;
    }
    UserService.updatePasswordByAdmin(this.ldapUser.username, this.newPassword)
      .then(() => {
        this.setLoading(false);
        this.showToast('Password updated', 'New password has been updated.', 'success');
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to update', 'Could not update user. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected cancel(): void {
    if (confirm('Do you really want to cancel? Unsaved changes will be lost.')) {
      this.$router.replace({ path: '/system' });
    }
  }

  protected getPossibleRoles(): string[] {
    return [
      UserRoleEnum.ROLE_ADMIN,
      UserRoleEnum.ROLE_REVIEWER,
      UserRoleEnum.ROLE_DESIGNER,
      UserRoleEnum.ROLE_CONFIGURATOR,
    ];
  }
}
